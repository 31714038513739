import { format, isValid, startOfMonth, startOfWeek, subWeeks } from 'date-fns';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LavendlaDatePicker from '../components/LavendlaDatePicker';
import API from '../stores/API';
import store from '../stores/RootStore';

class Export extends Component {
  state = {
    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endDate: null,
    loading: false,
    reportLoading: false,
    type: 'orders',
    latestReportUrl: null,
    latestReportDate: null
  };

  componentDidMount() {
    this.fetchLatestReport();
  }

  fetchLatestReport = async () => {
    this.setState({ reportLoading: true });

    try {
      const response = await API.GET('/export?type=orders_report');
      if (response.status === 'ok' && response.data.length > 0) {
        const latestReport = response.data[0];
        if (latestReport.files && latestReport.files.length > 0) {
          this.setState({
            latestReportUrl: latestReport.files[0].link,
            latestReportDate: latestReport.created_at
          });
        }
      }
    } catch (error) {
      console.error('Failed to fetch latest report:', error);
      store.noticeStore.showNotice(this.props.t('Failed to fetch the latest report. Please try again later.'), 'error');
    } finally {
      this.setState({ reportLoading: false });
    }
  };

  startDateChange = date => {
    const startDate = date || format(startOfWeek(subWeeks(new Date(), 9)), 'yyyy-MM-dd');
    this.setState({ startDate });
  };

  handleEndDateChange = date => {
    const endDate = date || format(new Date(), 'yyyy-MM-dd');
    this.setState({ endDate });
  };

  /**
   */
  generateExport = e => {
    e.preventDefault();

    const { startDate, endDate, type } = this.state;
    const { t } = this.props;

    API.POST('/export/orders', {
      start_date: startDate,
      end_date: endDate,
      type
    }).then(res => store.noticeStore.showNotice(t('The CSV file is being generated. It will be sent to your email when it is ready.')));
  };

  changeType = ({ target }) => {
    this.setState({ type: target.value });
  };

  formatReportDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm') : '';
  };

  render () {
    const { startDate, endDate, loading, type, latestReportUrl, latestReportDate, reportLoading } = this.state;
    const buttonClass = loading ? 'btn loading' : 'btn';
    const { t } = this.props;
    return (
      <div className='export'>
        <h2 className='page-title'>{t('Export')}</h2>

        <form className='datepicker-form' onSubmit={this.generateExport}>
          <div className='datepicker-container start-datepicker'>
            <label htmlFor='start_date'>{t('Start date')}</label>
            <LavendlaDatePicker
              date={startDate}
              onChange={this.startDateChange}
              isClearable={false}
              maxDate={new Date()}
            />
          </div>

          <div className='datepicker-container'>
            <label htmlFor='end_date'>{t('End date')}</label>
            <LavendlaDatePicker
              placeholder={t('Today')}
              date={endDate}
              onChange={this.endDateChange}
              maxDate={new Date()}
            />
          </div>

          <div className='dropdown-container'>
            <label htmlFor='type-of-order'>{t('Order type')}</label>
            <select name='type-of-order' id='type-of-order' onChange={this.changeType} value={type}>
              <option value='orders'>{t('Funeral orders')}</option>
              <option value='lawyer_orders'>{t('Law orders')}</option>
              <option value='tombstone_orders'>{t('Gravestone orders')}</option>
              <option value='therapy_orders'>{t('Therapy orders')}</option>
              <option value='ceremony_orders'>{t('Ceremony orders')}</option>
            </select>
          </div>

          <button className={buttonClass}>
            <span className='btn-text'>{t('Generate CSV')}</span>
            <span className='btn-loading'> </span>
          </button>
        </form>

        <h3>{t('Automatically generated reports')}</h3>
        <div className="report-section">
          {reportLoading ? (
            <div className="report-loading">
              <span>{t('Loading latest report...')}</span>
            </div>
          ) : latestReportUrl ? (
            <div className="latest-report">
              <div className="report-info">
                <h3 className="report-title">{t('Funeral and Gravestone orders for the last six months')}</h3>
                <p className="report-date">
                  {t('Created')}: {this.formatReportDate(latestReportDate)}
                </p>
              </div>
              <a
                href={latestReportUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                {t('Download Report')}
              </a>
            </div>
          ) : (
            <div className="empty-state">
              <p>{t('No reports available yet. Reports are generated automatically every tuesday morning.')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Export.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Export);
